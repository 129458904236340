import React from 'react';
import TeamItem from '../../../components/Homepage/Team2/TeamItem2';
import { Col, Container, Image, Row } from 'react-bootstrap';
import img from "../../../assets/images/homepage/section-3/1-tracejado.svg";
import img2 from "../../../assets/images/jobs/section-3/3-tracejado.svg";
import Spinner from '../../UI/Spinner/Spinner';
import { Suspense } from 'react';
const Carousel = React.lazy(() => import('../../UI/Carousel/Carousel'));


const Team = props => {

    const rita_segurado1 = require('../../../assets/images/team/Rita_Segurado_1.png');
    const rita_segurado1Webp = require('../../../assets/images/team/Rita_Segurado_1.webp');
    const rita_segurado2 = require('../../../assets/images/team/Rita_Segurado_2.png');
    const rita_segurado2Webp = require('../../../assets/images/team/Rita_Segurado_2.webp');

    const erica1 = require('../../../assets/images/team/Erica_1.png');
    const erica1Webp = require('../../../assets/images/team/Erica_1.webp');
    const erica2 = require('../../../assets/images/team/Erica_2.png');
    const erica2Webp = require('../../../assets/images/team/Erica_2.webp');

    const patricia1 = require('../../../assets/images/team/Patricia_1.png');
    const patricia1Webp = require('../../../assets/images/team/Patricia_1.webp');
    const patricia2 = require('../../../assets/images/team/Patricia_2.png');
    const patricia2Webp = require('../../../assets/images/team/Patricia_2.webp');

    const andre1 = require('../../../assets/images/team/Andre_1.png');
    const andre1Webp = require('../../../assets/images/team/Andre_1.webp');
    const andre2 = require('../../../assets/images/team/Andre_2.png');
    const andre2Webp = require('../../../assets/images/team/Andre_2.webp');

    const joao_madaleno1 = require('../../../assets/images/team/Joao_Madaleno_1.png');
    const joao_madaleno1Webp = require('../../../assets/images/team/Joao_Madaleno_1.webp');
    const joao_madaleno2 = require('../../../assets/images/team/Joao_Madaleno_2.png');
    const joao_madaleno2Webp = require('../../../assets/images/team/Joao_Madaleno_2.webp');

    const vanessa1 = require('../../../assets/images/team/Vanessa_1.png');
    const vanessa1Webp = require('../../../assets/images/team/Vanessa_1.webp');
    const vanessa2 = require('../../../assets/images/team/Vanessa_2.png');
    const vanessa2Webp = require('../../../assets/images/team/Vanessa_2.webp');

    const diogo1 = require('../../../assets/images/team/Diogo_1.png');
    const diogo1Webp = require('../../../assets/images/team/Diogo_1.webp');
    const diogo2 = require('../../../assets/images/team/Diogo_2.png');
    const diogo2Webp = require('../../../assets/images/team/Diogo_2.webp');

    const leandra1 = require('../../../assets/images/team/Leandra_1.png');
    const leandra1Webp = require('../../../assets/images/team/Leandra_1.webp');
    const leandra2 = require('../../../assets/images/team/Leandra_2.png');
    const leandra2Webp = require('../../../assets/images/team/Leandra_2.webp');

    const inesPinho1 = require('../../../assets/images/team/Ines_Pinho_1.png');
    const inesPinho1Webp = require('../../../assets/images/team/Ines_Pinho_1.webp');
    const inesPinho2 = require('../../../assets/images/team/Ines_Pinho_2.png');
    const inesPinho2Webp = require('../../../assets/images/team/Ines_Pinho_2.webp');

    const beatrizRoque1 = require('../../../assets/images/team/Beatriz_Roque_1.png');
    const beatrizRoque1Webp = require('../../../assets/images/team/Beatriz_Roque_1.webp');
    const beatrizRoque2 = require('../../../assets/images/team/Beatriz_Roque_2.png');
    const beatrizRoque2Webp = require('../../../assets/images/team/Beatriz_Roque_2.webp');

    function changeTeam(e) {
        const team = document.getElementById("team-members")
        const sales = document.getElementById("sales-group")
        const recruitment = document.getElementById("recruitment-group")
        if (e.target.id === "sales") {
            team.classList.add("sales")
            team.classList.remove("recruitment")
            sales.classList.add("d-block")
            sales.classList.remove("d-none")
            recruitment.classList.add("d-none")
            recruitment.classList.remove("d-block")
        } else {
            team.classList.remove("sales")
            team.classList.add("recruitment")
            sales.classList.remove("d-block")
            sales.classList.add("d-none")
            recruitment.classList.remove("d-none")
            recruitment.classList.add("d-block")
        }

    }

    return (
        <section id="team" className="my Team">
            <Image src={img} className="img-dashed-t  d-none d-lg-block" />
            <h2 className="h4 text-center fw-600 black mb-0">
                {props.t("homepage:section-4.title")}
            </h2>

            <Container id='team-members' className='team-members'>
                {/* tabs text */}
                <Row className='tab-text-group'>
                    <Col xs={4} className="p-0 p-md-3 d-flex justify-content-center align-items-center">
                        <p className='tab-text fw-600' id='sales' onClick={e => { changeTeam(e) }}>{props.t("homepage:section-4.business")}</p>
                    </Col>
                    <Col xs={{ span: 4, offset: 4 }} className="p-0 p-md-3 d-flex justify-content-center align-items-center">
                        <p className='tab-text fw-600' id='recruitment' onClick={e => { changeTeam(e) }}>{props.t("homepage:section-4.recruitment")}</p>
                    </Col>
                </Row>
                {/* sales */}
                <Container id='sales-group' className='sales-group'>
                    {/* carousel sales */}
                    <div className='d-lg-none'>
                        <Suspense fallback={<Spinner />}>
                            <Carousel className="teamSlide">
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-rita-segurado-1')}
                                    quote2={props.t('homepage:section-4.quote-rita-segurado-2')}
                                    email="rita.segurado@sysmatch.pt"
                                    name="Rita Segurado"
                                    position="Head of Sales"
                                    src1={rita_segurado1}
                                    src1Webp={rita_segurado1Webp}
                                    src2={rita_segurado2}
                                    src2Webp={rita_segurado2Webp}
                                    alt={props.t('homepage:section-4.rita-segurado_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-patricia-correia-1')}
                                    quote2={props.t('homepage:section-4.quote-patricia-correia-2')}
                                    email="patricia.correia@sysmatch.pt"
                                    name="Patrícia Correia"
                                    position="Business Unit Manager"
                                    src1={patricia1}
                                    src1Webp={patricia1Webp}
                                    src2={patricia2}
                                    src2Webp={patricia2Webp}
                                    alt={props.t('homepage:section-4.patricia-correia_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-vanessa-1')}
                                    quote2={props.t('homepage:section-4.quote-vanessa-2')}
                                    email="vanessa.pereira@sysmatch.pt"
                                    name="Vanessa Pereira"
                                    position="Business Unit Manager"
                                    src1={vanessa1}
                                    src1Webp={vanessa1Webp}
                                    src2={vanessa2}
                                    src2Webp={vanessa2Webp}
                                    alt={props.t('homepage:section-4.vanessa-alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-ines-pinho-1')}
                                    quote2={props.t('homepage:section-4.quote-ines-pinho-2')}
                                    email="ines.pinho@sysmatch.pt"
                                    name="Inês Pinho"
                                    position="Business Manager"
                                    src1={inesPinho1}
                                    src1Webp={inesPinho1Webp}
                                    src2={inesPinho2}
                                    src2Webp={inesPinho2Webp}
                                    alt={props.t('homepage:section-4.ines-pinho-alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-beatriz-roque-1')}
                                    quote2={props.t('homepage:section-4.quote-beatriz-roque-2')}
                                    email="beatriz.roque@sysmatch.pt"
                                    name="Beatriz Roque"
                                    position="Business Manager"
                                    src1={beatrizRoque1}
                                    src1Webp={beatrizRoque1Webp}
                                    src2={beatrizRoque2}
                                    src2Webp={beatrizRoque2Webp}
                                    alt={props.t('homepage:section-4.beatriz-roque-alt')}
                                />
                            </Carousel>
                        </Suspense>
                    </div>
                    <Row lg={3} className="px-4 d-none d-lg-flex">
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-rita-segurado-1')}
                            quote2={props.t('homepage:section-4.quote-rita-segurado-2')}
                            email="rita.segurado@sysmatch.pt"
                            name="Rita Segurado"
                            position="Head of Sales"
                            src1={rita_segurado1}
                            src1Webp={rita_segurado1Webp}
                            src2={rita_segurado2}
                            src2Webp={rita_segurado2Webp}
                            alt={props.t('homepage:section-4.rita-segurado_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-patricia-correia-1')}
                            quote2={props.t('homepage:section-4.quote-patricia-correia-2')}
                            email="patricia.correia@sysmatch.pt"
                            name="Patrícia Correia"
                            position="Business Unit Manager"
                            src1={patricia1}
                            src1Webp={patricia1Webp}
                            src2={patricia2}
                            src2Webp={patricia2Webp}
                            alt={props.t('homepage:section-4.patricia-correia_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-vanessa-1')}
                            quote2={props.t('homepage:section-4.quote-vanessa-2')}
                            email="vanessa.pereira@sysmatch.pt"
                            name="Vanessa Pereira"
                            position="Business Unit Manager"
                            src1={vanessa1}
                            src1Webp={vanessa1Webp}
                            src2={vanessa2}
                            src2Webp={vanessa2Webp}
                            alt={props.t('homepage:section-4.vanessa-alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-ines-pinho-1')}
                            quote2={props.t('homepage:section-4.quote-ines-pinho-2')}
                            email="ines.pinho@sysmatch.pt"
                            name="Inês Pinho"
                            position="Business Manager"
                            src1={inesPinho1}
                            src1Webp={inesPinho1Webp}
                            src2={inesPinho2}
                            src2Webp={inesPinho2Webp}
                            alt={props.t('homepage:section-4.ines-pinho-alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-beatriz-roque-1')}
                            quote2={props.t('homepage:section-4.quote-beatriz-roque-2')}
                            email="beatriz.roque@sysmatch.pt"
                            name="Beatriz Roque"
                            position="Business Manager"
                            src1={beatrizRoque1}
                            src1Webp={beatrizRoque1Webp}
                            src2={beatrizRoque2}
                            src2Webp={beatrizRoque2Webp}
                            alt={props.t('homepage:section-4.beatriz-roque-alt')}
                        />
                    </Row>
                </Container>
                {/* recruitment */}
                <Container id='recruitment-group' className='recruitment-group d-none'>
                    {/* carousel recruitment */}
                    <div className='d-lg-none'>
                        <Suspense fallback={<Spinner />}>
                            <Carousel className="teamSlide">
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-andre-1')}
                                    quote2={props.t('homepage:section-4.quote-andre-2')}
                                    email="andre.potes@sysmatch.pt"
                                    name="André Potes"
                                    position="Recruitment Unit Manager"
                                    src1={andre1}
                                    src1Webp={andre1Webp}
                                    src2={andre2}
                                    src2Webp={andre2Webp}
                                    alt={props.t('homepage:section-4.andre_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-erica-figueiredo-1')}
                                    quote2={props.t('homepage:section-4.quote-erica-figueiredo-2')}
                                    email="erica.figueiredo@sysmatch.pt"
                                    name="Erica Figueiredo"
                                    position="Recruitment Team Leader"
                                    src1={erica1}
                                    src1Webp={erica1Webp}
                                    src2={erica2}
                                    src2Webp={erica2Webp}
                                    alt={props.t('homepage:section-4.erica-figueiredo_alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-joao-madaleno-1')}
                                    quote2={props.t('homepage:section-4.quote-joao-madaleno-2')}
                                    email="joao.madaleno@sysmatch.pt"
                                    name="João Madaleno"
                                    position="IT Recruiter"
                                    src1={joao_madaleno1}
                                    src1Webp={joao_madaleno1Webp}
                                    src2={joao_madaleno2}
                                    src2Webp={joao_madaleno2Webp}
                                    alt={props.t('homepage:section-4.joao_madaleno-alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-diogo-1')}
                                    quote2={props.t('homepage:section-4.quote-diogo-2')}
                                    email="diogo.catalarranas@sysmatch.pt"
                                    name="Diogo Catalarranas"
                                    position="IT Recruiter"
                                    src1={diogo1}
                                    src1Webp={diogo1Webp}
                                    src2={diogo2}
                                    src2Webp={diogo2Webp}
                                    alt={props.t('homepage:section-4.diogo-alt')}
                                />
                                <TeamItem
                                    quote1={props.t('homepage:section-4.quote-leandra-1')}
                                    quote2={props.t('homepage:section-4.quote-leandra-2')}
                                    email="leandra.rabottini@sysmatch.pt"
                                    name="Leandra Rabottini"
                                    position="IT Recruiter"
                                    src1={leandra1}
                                    src1Webp={leandra1Webp}
                                    src2={leandra2}
                                    src2Webp={leandra2Webp}
                                    alt={props.t('homepage:section-4.leandra-alt')}
                                />
                            </Carousel>
                        </Suspense>
                    </div>
                    <Row lg={3} className="px-4 d-none d-lg-flex">
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-andre-1')}
                            quote2={props.t('homepage:section-4.quote-andre-2')}
                            email="andre.potes@sysmatch.pt"
                            name="André Potes"
                            position="Recruitment Unit Manager"
                            src1={andre1}
                            src1Webp={andre1Webp}
                            src2={andre2}
                            src2Webp={andre2Webp}
                            alt={props.t('homepage:section-4.andre_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-erica-figueiredo-1')}
                            quote2={props.t('homepage:section-4.quote-erica-figueiredo-2')}
                            email="erica.figueiredo@sysmatch.pt"
                            name="Erica Figueiredo"
                            position="Recruitment Team Leader"
                            src1={erica1}
                            src1Webp={erica1Webp}
                            src2={erica2}
                            src2Webp={erica2Webp}
                            alt={props.t('homepage:section-4.erica-figueiredo_alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-joao-madaleno-1')}
                            quote2={props.t('homepage:section-4.quote-joao-madaleno-2')}
                            email="joao.madaleno@sysmatch.pt"
                            name="João Madaleno"
                            position="IT Recruiter"
                            src1={joao_madaleno1}
                            src1Webp={joao_madaleno1Webp}
                            src2={joao_madaleno2}
                            src2Webp={joao_madaleno2Webp}
                            alt={props.t('homepage:section-4.joao_madaleno-alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-diogo-1')}
                            quote2={props.t('homepage:section-4.quote-diogo-2')}
                            email="diogo.catalarranas@sysmatch.pt"
                            name="Diogo Catalarranas"
                            position="IT Recruiter"
                            src1={diogo1}
                            src1Webp={diogo1Webp}
                            src2={diogo2}
                            src2Webp={diogo2Webp}
                            alt={props.t('homepage:section-4.diogo-alt')}
                        />
                        <TeamItem
                            quote1={props.t('homepage:section-4.quote-leandra-1')}
                            quote2={props.t('homepage:section-4.quote-leandra-2')}
                            email="leandra.rabottini@sysmatch.pt"
                            name="Leandra Rabottini"
                            position="IT Recruiter"
                            src1={leandra1}
                            src1Webp={leandra1Webp}
                            src2={leandra2}
                            src2Webp={leandra2Webp}
                            alt={props.t('homepage:section-4.leandra-alt')}
                        />
                    </Row>
                </Container>
            </Container>

            <Image src={img2} className="img-dashed-b d-none d-lg-block" />
        </section>
    );
}

export default Team;
