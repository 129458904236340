import React from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container';
//images
import logoInovaria from '../../../assets/images/certifications/logo-inovaria.svg';
import logoMicrosoft from '../../../assets/images/certifications/logo-microsoft.svg';
import logo9001 from '../../../assets/images/certifications/logo-iso9001.svg';
import logo27001 from '../../../assets/images/certifications/logo-iso27001.svg';
import logo4457 from '../../../assets/images/certifications/logo-iso4457.svg';
import inovadora from '../../../assets/images/certifications/Logo-Inovadora-24.svg';
import pme from '../../../assets/images/certifications/logo-PME-24.svg';
//files
import pdf9001 from '../../../assets/files/ISO-9001.pdf';
import pdf27001 from '../../../assets/files/ISO-27001.pdf';
import pdf4457 from '../../../assets/files/NP-4457.pdf';


const certifications = props => (
    <section
        id="homepage-section10"
        className="my">
        <Container>
            <Row className="justify-content-md-center">
                <Col xs="5" sm="4" md="3" lg="3" className="pb-4">
                    <p>{props.t("homepage:section-9.txt1")}</p>
                    <Row className="d-flex align-items-center">
                        <Col xs md="6" className="pr-3">
                            <img src={logoInovaria} alt="Logo Inovaria" />
                        </Col>
                        <Col xs md="6" className="px-3">
                            <img src={logoMicrosoft} alt="Logo Microsoft" />
                        </Col>
                    </Row>
                </Col>
                <Col xs="10" sm="8" md="6" lg="5" className="pb-4">
                    <p>{props.t("homepage:section-9.txt3")}</p>
                    <div className="d-flex align-items-center">
                        <Col xs lg="4" className="px-2 inovadora">
                            <img src={inovadora} alt="Empresa certificada Inovadora Cortec 2022" />
                        </Col>
                        <Col xs className="px-2 smaller-certification">
                            <img src={pme} alt="PME Líder 2021" />
                        </Col>
                        <Col xs className="px-2 smaller-certification">
                            <Link to={pdf9001} target="blank">
                                <img src={logo9001} alt="Empresa certificada ISO9001" />
                            </Link>
                        </Col>
                        <Col xs className="px-2 smaller-certification">
                            <Link to={pdf27001} target="blank">
                                <img src={logo27001} alt="Empresa certificada ISO27001" />
                            </Link>
                        </Col>
                        <Col xs className="px-2 smaller-certification">
                            <Link to={pdf4457} target="blank">
                                <img src={logo4457} alt="Empresa certificada ISO4457" />
                            </Link>
                        </Col>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
)

export default certifications;
